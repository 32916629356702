import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Carousel3d from "vue-carousel-3d";
import VueScrollReveal from "vue-scroll-reveal";

Vue.use(VueScrollReveal, {
  class: "v-scroll-reveal",
  duration: 800,
  scale: 0.9,
  distance: "30px",
  mobile: true,
});
Vue.use(Carousel3d);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
