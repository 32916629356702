<template>
  <div class="roadmap" id="roadmap">
    <div class="container">
      <div class="rm-head">
        <h1>Roadmap</h1>
        <!-- <h2>Wow such roadmap.</h2> -->
      </div>

      <div class="rm-area">
        <div class="planet1" :style="{ opacity: area25 }">
          <div
            :style="{
              transform: `translateY(calc(500px - ${500 * area25}px))`,
            }"
          >
            <img src="@/assets/l1.gif" />
          </div>
        </div>
        <div class="planet2" :style="{ opacity: area50 }">
          <div
            :style="{
              transform: `translateY(calc(500px - ${500 * area50}px))`,
            }"
          >
            <img src="@/assets/l2.gif" />
          </div>
        </div>
        <div class="planet3" :style="{ opacity: area75 }">
          <div
            :style="{
              transform: `translateY(calc(500px - ${500 * area75}px))`,
            }"
          >
            <img src="@/assets/l3.gif" />
          </div>
        </div>
        <div class="planet4" :style="{ opacity: area100 }">
          <div
            :style="{
              transform: `translateY(calc(500px - ${500 * area100}px))`,
            }"
          >
            <img src="@/assets/l4.gif" />
          </div>
        </div>
        <div
          class="area25"
          :style="{ transform: `translateY(calc(500px - ${500 * area25}px))` }"
        >
          <span class="goal donation">Q4 2021 + Q1 2022</span>
          <h1>Phase 1</h1>
          <div class="description">
            <p>
              <ul>
                <li><b>November 6th 2021</b> - $PIXL Utility Token Launch</li>
                <li><b>Decemeber 1st, 2021</b> - $PIXL Marketplace Launched</li>
                <li><b>December 21st, 2021</b> - AlphaV2 Pixlverse Homes module launched! Scalable multi-player network architecture achieved</li>
                <li><b>February 4th-5th, 2022</b> - Genesis Pixl Pets Mint</li>
              </ul>
            </p>
          </div>
        </div>
        <div
          class="area50"
          :style="{ transform: `translateY(calc(500px - ${500 * area50}px))` }"
        >
          <span class="goal merch">Q1/Q2 2022</span>
          <h1>Phase 2</h1>
          <div class="description">
            <p>
             <ul>
                <li>Gamified Yield Farming - 1st iteration of Pixlverse "Farms" module </li>
                <li>Layer 2 Scaling Solution</li>
                <li>Pixl Pet Breeding</li>
                <li>Pixlverse Land + Dev Grant program </li>
                <li>Begin Development of Founding Community HQs</li>
                <li>Pixlverse Open World "First Walkthroughs"</li>
              </ul>
            </p>
          </div>
        </div>
        <div
          class="area75"
          :style="{ transform: `translateY(calc(500px - ${500 * area75}px))` }"
        >
          <span class="goal donation">Q2/Q3 2022</span>
          <h1>Phase 3</h1>
          <div class="description">
            <p>
              <ul>
                <li>Pixl Pets Arena, Battling + Raids first Modules</li>
                <li>Pixlverse Guilds + Palaces</li>
                <li>Further development of the Open world</li>
              </ul>
            </p>
          </div>
        </div>
        <div
          class="area100"
          :style="{ transform: `translateY(calc(500px - ${500 * area100}px))` }"
        >
          <span class="goal drop">Q4 2022+</span>
          <h1>Phase 4</h1>
          <div class="description">
            <p>
              <ul>
                <li>SDK release</li>
                <li>Pixlverse Governance Token</li>
                <li>PixlverseDAO</li>
                <li>Pixlverse Founding Communities receive Governance tokens</li>
                <li>To Development and beyond…….</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <Mission /> -->
    <!-- <Team /> -->
  </div>
</template>

<script>
import Mission from "./Mission.vue";
import Team from "./Team.vue";

export default {
  components: {
    Mission,
    Team,
  },
  data() {
    return {
      area25: 0,
      area50: 0,
      area75: 0,
      area100: 0,
    };
  },
  methods: {
    deviceWidth() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width;
    },
    updateScroll() {
      let vm = this;
      vm.scrollPosition = window.scrollY;
      if (vm.scrollPosition < 2100) {
        return;
      }
      if (vm.deviceWidth() < 1200) {
        if (vm.area100 != 1) {
          vm.area25 = vm.area50 = vm.area75 = vm.area100 = 1;
        }
        return;
      }
      // bit messy bit reduces amount of mutations to state
      const pos25 = (vm.scrollPosition - 2100) / 180;
      if (pos25 > 0 && !(pos25 >= 1 && vm.area25 === 1)) {
        vm.area25 = pos25 < 1 ? pos25 : 1;
      }
      const pos50 = (vm.scrollPosition - 2300) / 200;
      if (pos50 > 0 && !(pos50 >= 1 && vm.area50 === 1)) {
        vm.area50 = pos50 < 1 ? pos50 : 1;
      }
      const pos75 = (vm.scrollPosition - 2400) / 380;
      if (pos75 > 0 && !(pos75 >= 1 && vm.area75 === 1)) {
        vm.area75 = pos75 < 1 ? pos75 : 1;
      }
      const pos100 = (vm.scrollPosition - 2600) / 520;
      if (pos100 > 0 && !(pos100 >= 1 && vm.area100 === 1)) {
        vm.area100 = pos100 < 1 ? pos100 : 1;
      }
    },
  },
  mounted() {
    let vm = this;
    window.addEventListener("scroll", vm.updateScroll);
  },
};
</script>

<style lang="scss">
@keyframes floatberg {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0%);
  }
}
a {
  color: white;
  text-decoration: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  text-shadow: -0.06em 0 red, 0.06em 0 cyan;
  cursor: pointer;
}
h1 {
  color: $white;
}
.roadmap {
  padding-top: 12rem;
  background: url("~@/assets/space-stars-1.png"),
    linear-gradient(74.9deg, #0c0a13 0%, #17001f 100%);
  .rm-area {
    display: grid;
  }
  .planet {
    grid-area: planet;
    animation: floatberg 3s ease-in-out infinite;
  }
  .planet1,
  .planet2,
  .planet3,
  .planet4 {
    display: none;
    > div {
      transition: transform 0.2s;
    }
  }
  .area25 {
    grid-area: area25;
    margin-bottom: 2rem;
  }
  .area50 {
    grid-area: area50;
    margin-bottom: 2rem;
  }
  .area75 {
    grid-area: area75;
    margin-bottom: 2rem;
  }
  .area100 {
    grid-area: area100;
    margin-bottom: 2rem;
  }
  .rm-head {
    margin-bottom: 4rem;
    h1 {
      font-family: $primary-font;
      text-shadow: $text-shadow-3d;
      margin-bottom: 1rem;
      line-height: 2rem;
    }
    h2 {
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 1rem;
      line-height: 1.2rem;
    }
  }
  .rm-item,
  .rm-area {
    grid-template-areas:
      "area25"
      "area50"
      "area75"
      "area100";
    padding-bottom: 10rem;
    h1 {
      margin-left: 3px;
    }
    .description ul {
      padding-left: 1rem;
      li {
        line-height: 150%;
        margin-bottom: 0.1rem;
      }
    }
    .goal {
      padding: 0.35rem 1rem;
      border-radius: 75px;
      font-size: 0.75rem;
      margin-bottom: 1rem;
      display: inline-block;
      &.donation {
        background: #103a10;
        color: #87ff87;
      }
      &.merch {
        background: #2b103a;
        color: #d387ff;
      }
      &.drop {
        background: #3a2410;
        color: #ffc187;
      }
    }
    p {
      color: white;
      font-size: 0.85rem;
    }
  }
}
@media screen and(min-width: $screen-sm) {
  .roadmap {
    .rm-head {
      h1 {
        font-size: 2.5rem;
      }
      h2 {
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 1rem;
        line-height: 1.2rem;
        font-size: 1.25rem;
      }
    }
    .rm-area {
      grid-auto-columns: 1fr;
      gap: 0px 0px;
      .goal {
        // padding-top: 2rem;
        margin-bottom: 1.5rem;
      }
      h1 {
        padding-left: 3px;
        padding-bottom: 1rem;
      }
      .planet1,
      .planet2,
      .planet3,
      .planet4 {
        display: none;
      }
      p {
        font-size: 1rem;
        position: relative;
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .roadmap {
    .rm-head {
      h1 {
        font-size: 2.5rem;
      }
      h2 {
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 1rem;
        line-height: 1.2rem;
        font-size: 1.25rem;
      }
    }
    .rm-area {
      grid-auto-columns: 1fr;
      gap: 0;
      grid-template-areas:
        "area25"
        "area50"
        "area75"
        "area100";
    }
  }
}
@media screen and (min-width: $screen-md) {
  .roadmap {
    .rm-head {
      h1 {
        font-size: 2.5rem;
      }
      h2 {
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 1rem;
        line-height: 1.2rem;
        font-size: 1.25rem;
      }
    }
    .rm-area {
      grid-template-areas:
        "area25 planet1 ."
        ". planet2 area50"
        "area75 planet3 ."
        ". planet4 area100";
      .area25,
      .area100,
      .area75,
      .area50 {
        position: relative;
        z-index: 1;
        transition: transform 0.2s;
        h1 {
          position: relative;
        }
        .description {
          font-weight: 300;
          margin-top: 0.75rem;
          position: absolute;
          ul {
            padding-left: 1rem;
            li {
              line-height: 150%;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
      .area25 {
        height: 200px;
        margin-top: 2rem;
        margin-bottom: 0;
      }
      .area50 {
        height: 300px;
        margin-top: -8rem;
        margin-bottom: 0;
        .description ul {
          direction: rtl;
          padding-left: 0;
          padding-right: 1rem;
        }
      }
      .area75 {
        height: 400px;
        margin-top: 3rem;
        margin-bottom: 0;
      }
      .area100 {
        margin-top: -7rem;
        height: 400px;
        margin-bottom: 0;
        .description ul {
          direction: rtl;
          padding-left: 0;
          padding-right: 1rem;
        }
      }

      .area25,
      .area75 {
        h1 {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            bottom: -10px;
            right: -50%;
            width: 150%;
            height: 2px;
            border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
            z-index: -5;
          }
        }
        .description {
          padding-right: 5rem;
        }
      }

      .area50,
      .area100 {
        text-align: right;
        h1 {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: -50%;
            width: 150%;
            height: 2px;
            border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
            z-index: -5;
          }
        }
        .description {
          padding-left: 5rem;
        }
      }

      .planet1,
      .planet2,
      .planet3,
      .planet4 {
        display: flex;
        justify-content: center;
        // margin-bottom: -4.5rem;
        z-index: 1231232;
        animation: floatberg 5s ease-in-out infinite;
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          > img {
            image-rendering: pixelated;
          }
        }
      }
      .planet1 {
        margin-top: 1.5rem;
        grid-area: planet1;
        animation-delay: 0;
        filter: drop-shadow(0px 0 40px rgba(#ffea8f, 0.5));
        > div {
          width: 100%;
          img {
            width: 50%;
            height: auto;
          }
        }
      }
      .planet2 {
        margin-top: -4rem;
        grid-area: planet2;
        animation-delay: 400ms;
        filter: drop-shadow(0px 0 40px rgba(#432a3f, 0.5));
        > div {
          width: 100%;
          img {
            width: 65%;
            height: auto;
          }
        }
      }
      .planet3 {
        margin-top: -4rem;
        grid-area: planet3;
        animation-delay: 800ms;
        filter: drop-shadow(0px 0 40px rgba(#57a4bb, 0.5));
        > div {
          width: 100%;
          img {
            width: 85%;
            height: auto;
          }
        }
      }
      .planet4 {
        grid-area: planet4;
        animation-delay: 1200ms;
        margin-top: -14rem;
        filter: drop-shadow(0px 0 40px rgba(#365759, 0.5));
        > div {
          width: 100%;
          img {
            width: 100%;
            height: auto;
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
