<template>
  <div class="mission" id="mission">
    <div class="container"  v-scroll-reveal="{ delay: 450 }">
      <div class="bf-head">
        <h1>Mission</h1>
      </div>
      <p>
        The Pixlverse is a true Web3 metaverse platform. We alongside our
        friends in the greater NFT community have set out to build the first
        truly collaborative, community owned &amp; created, metaverse platform.
      </p>
      <p>
        Gone are the days we bow down to our corporate overlords, them siphoning
        off 99% of the value and resources from said platform to just a few
        centralized parties. All the while violating consumer rights left right
        and down the center. Why? Because they could. The Pixlverse is proud to
        be on the cutting edge of DAO Decentralized governance models, Defi,
        Gamification and Community collaboration. Forever putting an end to this
        power imbalance.
      </p>
      <p>
        "Stronger Together" is more than just a meme around here. In the
        Pixlverse we live that ethos with every decision we make. Together we
        are truly greater than the sum of our parts.
      </p>
      <p>
        For greater detail on our full vision, check out our <a href="/litepaper">Litepaper</a>!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mission",
  components: {},
  mounted() {},
  methods: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.mission {
  > .container {
    padding-top: 5rem;
    padding-bottom: 13rem;
    .bf-head {
      padding: 8rem 0 2rem 0;
    }
    h1 {
      font-family: $primary-font;
      text-shadow: $text-shadow-3d;
    }
    p {
      font-weight: 300;
      color: $white;
      font-size: 18px;
      line-height: 35px;
    }
    a {
      pointer-events: all;
    }
  }
}
@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
