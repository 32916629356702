import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Litepaper from "../views/Litepaper.vue";
import Hatching from "../views/Hatching.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/litepaper",
    name: "Litepaper",
    component: Litepaper,
  },
  {
    path: "/hatch",
    name: "Hatch",
    component: Hatching,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
