<template>
  <div class="quantity-select">
    <div
      :class="{ minus: true, disabled: value - 1 < min }"
      @click="decrease()"
    >
      &minus;
    </div>
    <div class="amount">{{ value }}</div>
    <div @click="increase()" :class="{ plus: true, disabled: value + 1 > max }">
      &plus;
    </div>
  </div>
</template>

<script>
export default {
  name: "QuantitySelector",
  props: {
    min: Number,
    max: Number,
  },
  methods: {
    increase() {
      const vm = this;
      if (vm.value + 1 <= vm.max) {
        vm.value += 1;
        vm.$emit("change", vm.value);
      }
    },
    decrease() {
      const vm = this;
      if (vm.value - 1 >= vm.min) {
        vm.value -= 1;
        vm.$emit("change", vm.value);
      }
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      value: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.quantity-select {
  color: white;
  display: inline-flex;
  margin-top: 1.5rem;
  .amount {
    padding: 0.75rem 3rem;
    font-size: 1.5rem;
    line-height: 1.25rem;
    min-width: 2rem;
  }
  .minus,
  .plus {
    display: flex;
    align-items: center;
    // background: rgba(white, 0.5);
    background: rgba(white, 0.15);
    padding: 0 1rem;
    line-height: 0;
    font-size: 1.25rem;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
    &:hover {
      background: rgba(white, 0.25);
    }
  }
}
</style>
