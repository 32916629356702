<template>
  <div id="app">
    <router-view />
    <MintModal v-if="$store.state.mintModal" />
    <Footer />
  </div>
</template>
<script>
import Footer from "./components/Footer";
import MintModal from "./components/modals/MintModal";
export default {
  components: {
    Footer,
    MintModal,
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/web3modal";
.container {
  max-width: 90%;
  margin: 0 auto;
  position: relative;
}
body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;

  &:after {
    position: fixed;
    top: 0;
    left: 0;
    background: //linear-gradient(74.9deg, #0d0a1a 0%, #17001f 100%),
      url("~@/assets/bgpixlverse.gif") no-repeat;
    image-rendering: pixelated;
    background-blend-mode: screen;
    background-position: top center;
    background-size: cover;
    content: "";
    height: 100%;
    max-height: 100vh;
    width: 100%;
    opacity: 1;
    z-index: -1;
  }
}
h1,
h2,
h3 {
  margin-block-start: 0;
  margin-block-end: 0;
}

@-webkit-keyframes Bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes Bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes Bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  .container {
    max-width: 90%;
    margin: 0 auto;
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
  .container {
    max-width: 1280px;
    margin: 0 auto;
  }
}
</style>
