import state from "../state";
import Web3 from "web3";
import Web3Modal from "web3modal";
import axios from "axios";

import { toHHMMSS } from "../../helpers/timer";

import PixelPetsAbi from "../../data/abi/ERC721.json";

export default {
  async ConnectWallet(context) {
    console.log("network", process.env.VUE_APP_CONTRACT_NETWORK);
    const web3modal = new Web3Modal({
      network: process.env.VUE_APP_CONTRACT_NETWORK,
      cacheProvider: false,
      providerOptions: state.providerOptions,
    });
    const provider = await web3modal.connect();
    context.commit("SetProvider", provider);

    context.commit("SetWeb3", new Web3(state.provider));

    const address = (await state.web3.eth.getAccounts())[0] || null;
    context.commit("SetAccount", address);

    // await context.dispatch("FetchMerkleProof", state.account);
  },
  async CreateAccount(context) {
    try {
      let nonce = null;

      // check if account exists, else create it
      try {
        const { data: accountData } = await axios.get(
          `${process.env.VUE_APP_WHITELIST_API_URL}/users/${state.account}`
        );
        nonce = accountData.nonce;
      } catch (err) {
        const { data: createdData } = await axios.post(
          `${process.env.VUE_APP_WHITELIST_API_URL}/users`,
          { publicAddress: state.account }
        );
        nonce = createdData.nonce;
      }

      // sign the nonce
      const signature = await state.web3.eth.personal.sign(
        `I am signing my one-time nonce: ${nonce}`,
        state.account,
        ""
      );

      // pass the nonce to the auth
      const { data: authData } = await axios.post(
        `${process.env.VUE_APP_WHITELIST_API_URL}/auth`,
        {
          publicAddress: state.account,
          signature,
        }
      );

      // store the access token into localstorage
      localStorage.setItem("pv-jwt", authData.accessToken);
      await context.dispatch("FetchUserNfts");
      return true;
    } catch (err) {
      context.commit("SetProvider", null);
      context.commit("SetWeb3", null);
      context.commit("SetAccount", null);
      return false;
    }
  },
  async FetchUserNfts(context) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_WHITELIST_API_URL}/pixlPets/?publicAddress=${state.account}` +
        `&page=${state.hatching.currentPage * 20}&size=20`
    );
    const existingTokens = state.hatching.nfts.map((n) => n.token_id);
    const uniqueAssets = data.assets.filter(
      (n) => !existingTokens.includes(n.token_id)
    );
    context.commit("SetHatching", {
      ...state.hatching,
      ...{ nfts: [...state.hatching.nfts, ...uniqueAssets], nftsLoaded: true },
    });
  },
  async HatchEgg(context, { nft }) {
    try {
      await context.commit("SetHatching", {
        ...state.hatching,
        ...{ started: true },
      });

      // sign the egg
      const signature = await state.web3.eth.personal.sign(
        `I want to hatch egg: #${nft.token_id}`,
        state.account,
        ""
      );

      // post request
      const { data } = await axios.post(
        `${process.env.VUE_APP_WHITELIST_API_URL}/pixlPets`,
        { petId: nft.token_id, signature },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pv-jwt")}`,
          },
        }
      );

      // update the egg
      const newNfts = [...state.hatching.nfts].map((e) => {
        if (e.token_id === data._id) {
          return Object.assign(e, {
            isHatched: true,
            image_preview_url: data.metadata.image,
            token_metadata: data.metadata,
          });
        }
        return e;
      });

      // update
      await context.commit("SetHatching", {
        ...state.hatching,
        ...{
          nfts: newNfts,
          started: false,
          recentHatch: data,
          currentlyHatching: { ...nft },
        },
      });
      return true;
    } catch (err) {
      await context.commit("SetHatching", {
        ...state.hatching,
        ...{ started: false },
      });
      return false;
    }
  },
  async FetchMerkleProof(context, account) {
    console.log("FetchMerkleProof - func");
    const { data } = await axios.get(
      `${process.env.VUE_APP_WHITELIST_API_URL}/${account}`
    );
    if (data.success === true) {
      let merkleProof = { ...data.message };
      console.log("FetchMerkleProof", merkleProof);
      if (!merkleProof.whitelist.proof.length) {
        merkleProof.whitelist.proof = ["0x"];
      }
      if (!merkleProof.free.proof.length) {
        merkleProof.free.proof = ["0x"];
      }
      await context.commit("SetMerkleProof", merkleProof);
      await context.dispatch("FetchMintProgress", state.account);
    }
  },
  async FetchMintProgress(context) {
    console.log("FetchMintProgress - func");

    const contract = new state.web3.eth.Contract(
      PixelPetsAbi,
      process.env.VUE_APP_CONTRACT_ADDRESS
    );
    // console.log("FetchMintProgress - func", state.merkleProof);

    // console.log("FetchMintProgress - func", state.account);

    const result = await contract.methods
      .getMintProgress(
        state.account,
        state.merkleProof.free.proof,
        state.merkleProof.whitelist.proof
      )
      .call();
    context.commit("SetMintProgress", {
      loaded: true,
      freeMints: parseInt(result[0]),
      presaleMints: parseInt(result[1]),
      publicSaleMints: parseInt(result[2]),
      totalMinted: parseInt(result[3]),
      availableToMint: parseInt(result[4]),
    });
  },
  async FetchMintedSupply(context) {
    console.log("FetchMintedSupply");
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        `https://${process.env.VUE_APP_CONTRACT_NETWORK}.infura.io/v3/${process.env.VUE_APP_INFURA_ID}`
      )
    );
    const contract = new web3.eth.Contract(
      PixelPetsAbi,
      process.env.VUE_APP_CONTRACT_ADDRESS
    );
    const supply = await contract.methods.totalSupply().call();
    context.commit("SetTotalSupply", parseInt(supply));
  },
  async FetchCountDownText(context) {
    const currentTime = new Date().getTime() / 1000;
    context.commit("SetCurrentTime", currentTime);
    const { presaleTime, presaleCloseTime, publicSaleTime } = state;
    let finalText = "Public sale has been opened!";
    if (currentTime < presaleTime) {
      finalText = `Pre-sale opens in ${toHHMMSS(
        presaleTime - currentTime
      )}\nPublic sale opens in ${toHHMMSS(publicSaleTime - currentTime)}`;
    } else if (currentTime < publicSaleTime) {
      if (currentTime < presaleCloseTime) {
        finalText = `Pre-sale opened! Pre-sale closes in ${toHHMMSS(
          presaleCloseTime - currentTime
        )}\nPublic sale opens in ${toHHMMSS(publicSaleTime - currentTime)}`;
      } else {
        finalText = `Pre-sale closed!\nPublic sale opens in ${toHHMMSS(
          publicSaleTime - currentTime
        )}`;
      }
    }

    if (
      state.totalSupply >= parseInt(process.env.VUE_APP_MAX_MINTABLE_SUPPLY)
    ) {
      finalText = "Pixl Pets are sold out!";
    }

    context.commit("SetCountDownText", finalText);
  },
};
