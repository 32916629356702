var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pixel-pets",attrs:{"id":"pixel-pets"}},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({ delay: 450 }),expression:"{ delay: 450 }"}],staticClass:"container"},[_vm._m(0)]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({ delay: 450 }),expression:"{ delay: 450 }"}],staticClass:"container pet-info"},[_c('div',{staticClass:"eggs"},[_vm._l((_vm.eggs),function(egg){return _c('div',{key:egg._id,staticClass:"egg vertical"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":egg.img}})]),_c('div',{staticClass:"info centered"},[_c('h2',[_vm._v(_vm._s(egg.name))]),(egg.description)?_c('p',[_vm._v(_vm._s(egg.description))]):_vm._e()])])}),_c('div',{staticClass:"cta"})],2),_c('Tilt',{attrs:{"options":{
        startX: _vm.deviceWidth() >= 760 ? 40 : 0,
        startY: _vm.deviceWidth() >= 760 ? 0 : 40,
        reset: true,
      }}},[_c('div',{staticClass:"pets"},_vm._l((_vm.pets),function(pet){
      var _obj;
return _c('div',{key:pet._id,class:( _obj = {
            pet: pet
          }, _obj[("pet" + (pet._id))] = true, _obj.small = pet.size === 'small', _obj )},[_c('img',{attrs:{"src":pet.img}})])}),0)])],1),_c('div',{staticClass:"plane"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pp-head"},[_c('h1',[_vm._v("Pixl Pets")]),_c('h2',[_vm._v("Your companions throughout the Pixlverse. "),_c('br'),_vm._v("Minting soon!")])])}]

export { render, staticRenderFns }