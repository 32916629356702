<template>
  <div class="bf-base">
    <div class="earth"></div>
    <div class="bf">

      <Mission />

      <PixelPets />

      <!--  <Roadmap /> -->

      <!-- partners -->
      <div id="partners">
        <div class="collection-wrap container" v-scroll-reveal="{ delay: 450 }">
          <div class="bf-head">
            <h1>Partners</h1>
            <h2>Strategic partnerships to the Pixlverse ecosystem</h2>
          </div>
        </div>
        <div
          class="collection-grid"
          v-scroll-reveal="{ delay: 450 }"
          v-for="(row, i) in collection"
          v-bind:key="i"
        >
          <InfiniteSlideBar
            class="collection-grid"
            :barStyle="{ background: 'transparent', padding: '5px 0' }"
            :direction="i == 1 ? 'reverse' : 'normal'"
            :duration="'60s'"
          >
            <div v-for="(asset, i) in row" v-bind:key="i" class="scroll-row">
              <img class="scroll-img" :src="getPartnerImage(asset)" />
            </div>
          </InfiniteSlideBar>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import InfiniteSlideBar from "vue-infinite-slide-bar";
import PixelPets from "./PixelPets.vue";
import Partners from "../data/Partners.json";
import Mission from "./Mission.vue";
import Roadmap from "./Roadmap.vue";
export default {
  name: "Partners",
  components: {
    InfiniteSlideBar,
    PixelPets,
    Mission,
    Roadmap,
  },
  mounted() {},
  methods: {
    getPartnerImage(partner) {
      return require("@/assets/partners/" + partner.img);
    },
  },
  data() {
    return {
      collection: Partners,
    };
  },
};
</script>

<style lang="scss">
.bf-base {
  margin-top: 50vh;
  position: relative;
}

@keyframes glow {
  0% {
    filter: drop-shadow(0px 0 40px rgba(#36b5bb, 0.5));
  }
  50% {
    filter: drop-shadow(0px 0 40px rgba(#36b5bb, 0.1));
  }
  100% {
    filter: drop-shadow(0px 0 40px rgba(#36b5bb, 0.5));
  }
}

.earth {
  animation: glow 2s ease-in-out infinite;
  z-index: 1;
  background: url("~@/assets/earth.png") no-repeat;
  background-size: cover;
  background-position: bottom center;
  display: block;
  width: 100%;
  height: 23vh;
  position: relative;
  margin-bottom: -270px;
  pointer-events: none;
}

#partners {
  margin-bottom: 6rem;

  background: linear-gradient(
    180deg,
    rgba(61, 16, 85, 0) 0%,
    rgba(#380a56, 0.6) 50%,
    rgba(64, 19, 85, 0) 100%
  );

  .bf-head {
    padding: 0;
    margin-top: 6rem;
  }

}
.bf {
  pointer-events: none;
  position: relative;
  z-index: 9999;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 95%, transparent 100%);
  background: linear-gradient(74.9deg, #000000 0%, #17001f 100%);
  overflow: hidden;
  // text-align: center;
  .bf-head {
    margin-top: 12rem;
    padding-top: 12rem;
    padding-bottom: 5rem;
  }
  h1 {
    font-size: 2rem;
    font-weight: 900;
    font-family: $primary-font;
    text-shadow: -0.06em 0 red, 0.06em 0 cyan;
    margin-bottom: 1rem;
    line-height: 2rem;
    color: $white;
  }
  h2 {
    font-size: 1.2rem;

    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 1rem;
    line-height: 1.2rem;
  }
  .collection-grid {
    gap: 1rem;
    .scroll-row {
      display: inline-block;
      padding: 0.25rem 2rem;
    }
    img {
      padding: 0 0.5rem;
      max-width: 9rem;
      max-height: 9rem;
      // border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    }
  }
}
@media screen and (min-width: $screen-sm) {
  .bf-base {
    margin-top: 55vw;
  }
  .earth {
    height: 20vw;
    // margin-bottom: -115px;
    margin-bottom: -250px;
  }
}
@media screen and (min-width: $screen-md) {
  .bf-base {
    margin-top: 44vw;
  }
  .bf {
    text-align: left;
    .bf-head {
      // padding-top: 15rem;
      padding-bottom: 5rem;
    }
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 1.25rem;
    }
    .collection-grid {
      img {
        padding: 0 0.5rem;
        max-width: 17rem;
        border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .bf-base {
    margin-top: 30vw;
  }
}
@media screen and (min-width: $screen-xlg) {
  .bf-base {
    margin-top: 25vw;
  }
}
@media screen and (min-width: $screen-xxlg) {
  .bf-base {
    margin-top: unset;
  }
  .earth {
    height: 24vw;
    // margin-bottom: -90px;
  }
}
</style>
