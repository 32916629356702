<template>
  <div class="hero" :style="heroPos">
    <div class="entry-hero container" :style="blur">
      <div class="mint-details">
        <h1>Pixl Pets</h1>

        <h2>Minted {{ $store.state.totalSupply }} / {{ maxSupply }}</h2>
        <div class="pct-bar">
          <div
            class="bar"
            :style="{
              width: `${($store.state.totalSupply / maxSupply) * 100}%`,
            }"
          >
            <img src="@/assets/pixl.png" alt="" />
          </div>
        </div>

        <h3>
          {{ $store.state.countDownText }}
        </h3>

        <div class="btn-group" v-if="shouldMintButtonShow === true">
          <Button
            @btnClicked="wantsToMint"
            text="Mint Pixl Pet Now!"
            class="active"
          />
        </div>
      </div>
      <div class="pv-logo">
        <img class="brand" src="@/assets/thepixlverse-inverted.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./ui/Button";
export default {
  name: "HeroMintSection",
  components: {
    Button,
  },
  computed: {
    maxSupply: function () {
      return parseInt(process.env.VUE_APP_MAX_MINTABLE_SUPPLY);
    },
    shouldCountdownShow: function () {
      const vm = this;
      return vm.$store.state.totalSupply < vm.maxSupply;
    },
    shouldMintButtonShow: function () {
      const vm = this;
      // hide if not over the presale open
      if (vm.$store.state.currentTime <= vm.$store.state.presaleTime) {
        return false;
      }
      // hide mint button if supply is over 15k
      if (vm.$store.state.totalSupply >= vm.maxSupply) {
        return false;
      }
      // hide between presale close and public open
      if (
        vm.$store.state.currentTime >= vm.$store.state.presaleCloseTime &&
        vm.$store.state.currentTime < vm.$store.state.publicSaleTime
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    wantsToMint() {
      this.$store.state.mintModal = true;
    },
    updateScroll() {
      if (this.opac > 1 || this.opac < 0) {
        this.opac = 1;
      }
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 600) {
        this.blur = `display: none;`;
        return; // no point updating element
      }
      const translationSpeed = this.scrollPosition / 5000;
      const translationRules = [
        `translate(${translationSpeed * 250}px, ${translationSpeed * 1250}px)`,
        `scale(${1 - this.scrollPosition / 2800})`,
      ];
      this.heroPos = `transform: ${translationRules.join(" ")};`;
      this.blur = `opacity:${1 - this.scrollPosition / 700}`;
    },
  },
  mounted() {
    let vm = this;
    window.addEventListener("scroll", vm.updateScroll);
  },
  data() {
    return {
      heroPos: 0,
      scrollPosition: 0,
      blur: 0,
      mintedSoFar: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes progress-bar {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero {
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  position: fixed;
  transition: 0.1s ease;
  margin-top: 12vh;
  z-index: 1;

  .entry-hero {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row;
    gap: 5rem;
    // justify-items: center;
    // text-align: center;
    h1 {
      font-size: 2rem;
      font-weight: 900;
      font-family: $primary-font;
      text-shadow: -0.06em 0 red, 0.06em 0 cyan;
      margin-bottom: 3rem;
      margin-top: 1rem;
      line-height: 2rem;
      color: $white;
      white-space: nowrap;
    }
    h2 {
      font-size: 0.75rem;
      font-family: $primary-font;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 1rem;
      line-height: 1rem;
    }
    h3 {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 1rem;
      white-space: break-spaces;
      line-height: 2rem;
      font-size: 1.4em;
    }
    .mint-details {
      .pct-bar {
        background: rgba(255, 255, 255, 0.2);
        margin-top: 2rem;
        border-radius: 75px;
        // margin-right: 1.5rem;
        .bar {
          height: 0.5rem;
          margin-top: 2.5rem;
          margin-bottom: 2rem;
          position: relative;
          border-radius: 8px;
          background: linear-gradient(90deg, #0ea51a, #00a3ff, #8d00ff);
          background-size: 600% 600%;

          -webkit-animation: progress-bar 5s ease infinite;
          -moz-animation: progress-bar 5s ease infinite;
          animation: progress-bar 5s ease infinite;
          img {
            max-width: 3rem;
            position: absolute;
            top: -1.3rem;
            right: -2rem;
            animation: rock 2s ease-in-out infinite;
          }
        }
      }
    }
    .pv-logo {
      display: none;
      justify-content: center;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .brand {
      pointer-events: none;
      // opacity: 0.8;
    }
  }
}
@media screen and (min-width: $screen-sm) {
  .hero {
    margin-top: 22vw;
    .entry-hero {
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 1.1em;
      }
      .hero-item {
        .button {
          margin: 0 auto;
          max-width: 8rem;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .hero {
    margin-top: 20vw;
    .entry-hero {
      .pv-logo {
        width: 850px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .hero {
    margin-top: 14vw;
    .entry-hero {
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 1.25em;
      }
      grid-template-columns: repeat(2, 1fr);
      .pv-logo {
        padding-left: 50px;
        width: 550px;
        display: flex;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-xlg) {
  .hero {
    margin-top: 14vw;
    .entry-hero {
      .pv-logo {
        width: 550px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-xxlg) {
  .hero {
    .entry-hero {
      .pv-logo {
        width: 550px;
        margin-top: 30px;
      }
    }
  }
}
</style>
