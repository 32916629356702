export default {
  SetProvider(state, params) {
    return (state.provider = params);
  },
  SetWeb3(state, params) {
    return (state.web3 = params);
  },
  SetAccount(state, params) {
    return (state.account = params);
  },
  SetMerkleProof(state, params) {
    return (state.merkleProof = params);
  },
  SetMintProgress(state, params) {
    return (state.mintProgress = params);
  },
  SetTotalSupply(state, params) {
    return (state.totalSupply = params);
  },
  SetCountDownText(state, params) {
    return (state.countDownText = params);
  },
  SetCurrentTime(state, params) {
    return (state.currentTime = params);
  },
  SetHatching(state, params) {
    return (state.hatching = params);
  },
};
