<template>
  <div
    class="button"
    v-bind:class="{
      disabled: disabled === true,
      loading: loading === true,
      [size]: size !== '',
    }"
    @click="disabled !== true && Action()"
  >
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    action: String,
    text: String,
    href: String,
    loading: Boolean,
    disabled: Boolean,
    size: String,
  },
  methods: {
    MintDialog() {
      alert("Wallet Connected");
    },
    Action() {
      if (this.href) {
        window.location.href = this.href;
        return;
      }
      this.$emit("btnClicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.activeBtn {
  opacity: 1 !important;
}
.inactive {
  opacity: 0.4;
}
.button {
  padding: 1rem 1rem;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 0.85rem;
  box-shadow: -0.16em -0.2em red, 0.16em 0.2em cyan;
  text-align: center;
  vertical-align: middle;
  font-family: $primary-font;
  transition: 0.3s ease;
  cursor: pointer;
  transform: scale(1);
  user-select: none;
  &.lg {
    padding: 1.5rem 2.5rem;
    font-size: 1rem;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.95);
    box-shadow: -0.26em -0.3em cyan, 0.26em 0.3em red;
  }
  &:active {
    box-shadow: -0.16em -0.2em red, 0.16em 0.2em cyan;
    transform: scale(0.95);
  }
  &.loading {
    min-width: 115px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: unset;
    &:active {
      box-shadow: unset;
      transform: unset;
    }
  }
  .button-icon {
    svg {
      vertical-align: bottom;
    }
  }
}

.loading > span:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 2000ms infinite;
  animation: ellipsis steps(4, end) 2000ms infinite;
  content: "\2026";
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
</style>
