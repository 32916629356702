import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";

export default {
  // nft stats itself
  totalSupply: "...",
  countDownText: "...",

  // modals
  mintModal: false,

  // web3
  web3: null,
  provider: null,
  account: null,

  // hatcing
  hatching: {
    nfts: [],
    nftsLoaded: false,
    loadingErrorMsg: "",
    started: false,
    currentlyHatching: null,
    recentHatch: null,
    currentPage: 0,
  },

  // api
  merkleProof: {
    whitelist: {
      proof: null,
      valid: false,
    },
    free: {
      proof: null,
      valid: false,
    },
  },

  // mint progress for the account
  mintProgress: {
    loaded: false,
    freeMints: 0,
    presaleMints: 0,
    publicSaleMints: 0,
    totalMinted: 0,
    availableToMint: 0,
  },

  // transaction information
  tx: {
    started: false,
    completed: false,
    failed: false,
    failedMsg: null,
    hash: false,
  },

  // countdown settings
  currentTime: 0,
  presaleTime: 1644012000,
  presaleCloseTime: 1644094800,
  publicSaleTime: 1644100200,

  // web3 modal config
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: process.env.VUE_APP_INFURA_ID,
        network: process.env.VUE_APP_CONTRACT_NETWORK,
      },
    },
    walletlink: {
      package: WalletLink,
      options: {
        appName: "The Pixlverse",
        infuraId: process.env.VUE_APP_INFURA_ID,
        chainId: process.env.VUE_APP_CONTRACT_NETWORK === "rinkeby" ? 4 : 1,
      },
    },
  },
};
