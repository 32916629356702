<template>
  <div class="team" id="team">
    <div class="container">
      <div class="t-head" v-scroll-reveal="{ delay: 450 }">
        <h1>The Team</h1>
        <h2>The dream team</h2>
      </div>
      <div class="team-grid">
        <div
          class="team-member"
          v-for="(member, idx) in team"
          :key="idx"
          v-scroll-reveal="{ delay: 250 }"
        >
          <div class="image">
            <img :src="getTeamMemberImage(member)" />
          </div>
          <div class="name">{{ member.name }}</div>
          <div class="role">{{ member.role }}</div>
          <a
            v-if="member.twitter"
            :href="member.twitter"
            target="_blank"
            class="twitter"
          >
            <img src="~@/assets/twitter.png" />
          </a>
        </div>
      </div>
    </div>
    <div class="rainbow"></div>
  </div>
</template>

<script>
import Team from "../data/Team.json";
export default {
  name: "Team",
  components: {},
  mounted() {},
  methods: {
    getTeamMemberImage(member) {
      return require("@/assets/seals/" + member.img);
    },
  },
  data() {
    return {
      team: Team,
    };
  },
};
</script>

<style lang="scss">
.team {
  position: relative;
  overflow: hidden;

  > .container {
    padding-top: 5rem;
    padding-bottom: 10rem;
    z-index: 2;
    h1 {
      font-family: $primary-font;
      text-shadow: $text-shadow-3d;
      margin-bottom: 1rem;
      line-height: 2rem;
    }
    h2 {
      color: rgba(255, 255, 255, 0.5);
      line-height: 1.2rem;
      font-size: 1.25rm;
      margin-bottom: 5rem;
    }

    .team-grid {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;

      .team-member {
        background: linear-gradient(
          180deg,
          #350756 0%,
          rgba(64, 19, 85, 0) 100%
        );
        border-radius: 10px;
        padding: 0.5rem;
        text-align: center;

        .image {
          margin-bottom: 1.5rem;
          img {
            width: 100%;
            border-radius: 10px;
          }
        }

        .name {
          font-family: $primary-font;
          font-size: 16px;
          // white-space:nowrap;
          color: $white;
          margin: 1.5rem 0;
          &.sm {
            font-size: 14px;
          }
        }

        .role {
          font-size: 16px;
          font-weight: 300;
          color: $white;
          margin: 1.5rem 0;
        }

        .twitter {
          border: none;
          img {
            width: 32px;
            height: 32px;
            transition: transform 0.2s;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  .rainbow {
    background: url("~@/assets/rainbow.svg") no-repeat;
    position: absolute;
    background-position: center;
    background-size: 100%;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: scale(5) rotateZ(-40deg);
    z-index: 1;
  }
}
@media screen and (min-width: $screen-sm) {
  .team {
    > .container {
      .team-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .rainbow {
      transform: scale(4) rotateZ(-20deg);
    }
  }
}
@media screen and (min-width: $screen-md) {
  .team {
    > .container {
      .team-grid {
        .team-member {
          .name {
            font-size: 18px;
            &.sm {
              font-size: 16px;
            }
          }
        }
      }
    }

    .rainbow {
      transform: scale(2);
    }
  }
}
@media screen and (min-width: $screen-lg) {
  .team {
    > .container {
      .team-grid {
        grid-template-columns: repeat(4, 1fr);
        .team-member {
          &:nth-child(1) {
            grid-column: 2;
          }
          &:nth-child(2) {
            grid-column: 3;
          }
          &:nth-child(3) {
            grid-column-start: 1;
          }
        }
      }
    }
    .rainbow {
      transform: unset;
    }
  }
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
