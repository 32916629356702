<template>
  <div>
    <div class="blur" />
    <Header />
    <div class="hatching">
      <div class="container" v-if="hideHatchUI">
        <div class="t-head">
          <h1>Hatching will be back soon!</h1>
          <h3>
            Please excuse this quick interruption, we are currently improving
            our Metadata!
          </h3>
        </div>
      </div>

      <div class="container" v-else>
        <div class="t-head">
          <h1>Hatch your Pixl Pets</h1>
        </div>

        <h3 v-if="$store.state.loadingErrorMsg !== ''">
          {{ $store.state.loadingErrorMsg }}
        </h3>

        <div class="connect-wallet" v-if="$store.state.provider == null">
          <Button text="Connect Wallet" @btnClicked="connectWallet" />
        </div>
        <h3 v-else-if="!$store.state.hatching.nftsLoaded">
          Sign the message to login.
        </h3>

        <div class="nft-grid">
          <div
            class="nft"
            v-for="(nft, idx) in $store.state.hatching.nfts"
            :key="idx"
          >
            <div class="image">
              <img
                :src="
                  nft.image_preview_url.replace(
                    'ipfs://',
                    'https://cloudflare-ipfs.com/ipfs/'
                  )
                "
              />
            </div>
            <div class="id">#{{ nft.token_id }}</div>
            <Button
              v-if="nft.isHatched"
              :text="'View on Opensea'"
              @btnClicked="viewOnOpensea(nft)"
            />
            <Button v-else :text="'Hatch'" @btnClicked="hatch(nft)" />
          </div>
          <div
            v-if="
              !$store.state.hatching.nfts.length &&
              $store.state.hatching.nftsLoaded
            "
            class="no-nfts"
          >
            <h3>You do not own any Pixl Pets</h3>
          </div>
        </div>

        <infinite-loading
          @infinite="infiniteHandler"
          v-if="
            $store.state.hatching.nftsLoaded &&
            $store.state.hatching.nfts.length
          "
        >
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>

        <div
          class="hatched"
          v-bind:class="{ 'video-ended': hatchingEnded }"
          v-if="revealHatch"
        >
          <h1 v-bind:style="{ opacity: hatchingEnded ? 1 : 0 }">
            {{ petRevealName }}
          </h1>
          <div class="video">
            <div
              class="video-container"
              v-bind:class="{
                shaking: !hatchingEnded,
                revealed: hatchingEnded,
                [selectedPetElement]: true,
              }"
            >
              <video
                autoplay
                muted
                @ended="hatchingVideoEnded"
                v-bind:class="{ ended: hatchingEnded }"
              >
                <source :src="eggVideoFromRecentHatch" type="video/webm" />
              </video>
              <div
                class="pet-reveal"
                v-bind:style="{ opacity: hatchingEnded ? 1 : 0 }"
              >
                <img :src="petRevealImage" />
                <!--<img :src="petRevealImageBackground" class="bg" />-->
              </div>
            </div>
          </div>
          <Button
            class="close"
            text="Close"
            @btnClicked="closeHatching"
            v-bind:style="{ opacity: hatchingEnded ? 1 : 0 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import Header from "../components/Header";
import Button from "../components/ui/Button";
export default {
  name: "Hatching",
  components: {
    InfiniteLoading,
    Header,
    Button,
  },
  computed: {
    hideHatchUI() {
      return process.env.VUE_APP_IS_HATCHING_ACTIVE === "false";
    },
    recentHatch() {
      return this.$store.state.hatching.recentHatch;
    },
    selectedPetElement() {
      const vm = this;
      const { currentlyHatching } = vm.$store.state.hatching;
      return currentlyHatching.token_metadata.attributes.find(
        (e) => e.trait_type === "Element"
      ).value;
    },
    petRevealImage() {
      const vm = this;
      return vm.recentHatch
        ? vm.recentHatch.metadata.image.replace(
            "ipfs://",
            "https://cloudflare-ipfs.com/ipfs/"
          )
        : "";
    },
    petRevealName() {
      const vm = this;
      return vm.recentHatch ? vm.recentHatch.metadata.name : "";
    },
    eggVideoFromRecentHatch() {
      return require(`@/assets/hatching/${this.selectedPetElement}.webm`);
    },
  },
  methods: {
    async infiniteHandler($state) {
      const vm = this;
      const previousNftCollectionSize = vm.$store.state.hatching.nfts.length;
      await vm.$store.commit("SetHatching", {
        ...vm.$store.state.hatching,
        ...{ currentPage: vm.$store.state.hatching.currentPage + 1 },
      });
      await vm.$store.dispatch("FetchUserNfts");
      if (previousNftCollectionSize !== vm.$store.state.hatching.nfts.length) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    async connectWallet() {
      const vm = this;
      await vm.$store.dispatch("ConnectWallet");
      await vm.$store.dispatch("CreateAccount");
    },
    async hatch(nft) {
      const vm = this;
      vm.revealHatch = await vm.$store.dispatch("HatchEgg", { nft });
    },
    async viewOnOpensea(nft) {
      window.open(
        "https://opensea.io/assets/0x4e76c23fe2a4e37b5e07b5625e17098baab86c18/" +
          nft.token_id,
        "_blank"
      );
    },
    async hatchingVideoEnded() {
      const vm = this;
      vm.hatchingEnded = true;
    },
    closeHatching() {
      const vm = this;
      vm.revealHatch = false;
      vm.hatchingEnded = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  data() {
    return {
      hatchingEnded: false,
      revealHatch: false,
    };
  },
};
</script>
<style lang="scss" scoped>
@keyframes hatching {
  100% {
    transform: translateY(0) scale(1);
  }
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.blur {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  width: 100vw;
  z-index: 0;
  backdrop-filter: blur(10px);
  pointer-events: none;
  height: 100vh;
}

.hatching {
  text-align: center;
  min-height: 85vh;

  > .container {
    margin-bottom: 10rem;
    text-align: center;
    h1 {
      font-family: $primary-font;
      text-shadow: $text-shadow-3d;
      margin: 4rem 0 6rem 0;
      line-height: 2rem;
    }
    h2,
    h3 {
      color: $white;
      font-family: $primary-font;
      // text-shadow: $text-shadow-3d;
    }
  }

  .connect-wallet {
    max-width: 500px;
    margin: 0 auto;
  }

  .hatched {
    // pointer-events: none;
    z-index: 9999;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0.8) 0%,
      #000000 100%
    );
    transition: all 0.2s;
    h1 {
      opacity: 0;
      margin-top: 0;
      margin-bottom: 2rem;
      transition: opacity 0.5s;
    }
    .button.close {
      margin-top: 2rem;
      opacity: 0;
      transition: opacity 0.5s;
    }
    .video {
      position: relative;
      transform: translateY(50rem) scale(0.1);
      // border-radius: 10px;
      line-height: 0;
      // overflow: hidden;
      animation: 4s hatching;
      animation-fill-mode: forwards;
      &-container {
        position: relative;
        &.Earth {
          filter: drop-shadow(0 0 125px #3f795c);
        }
        &.Air {
          filter: drop-shadow(0 0 125px #ecf4f7);
        }
        &.Fire {
          filter: drop-shadow(0 0 125px #800f19);
        }
        &.Water {
          filter: drop-shadow(0 0 125px #0e86f7);
        }
        border-radius: 10px;
        // transition: all 0.2s;
        overflow: hidden;
        video.ended {
          opacity: 0;
        }
        &.revealed {
          width: 285px;
          height: 477px;
        }
        &.shaking {
          position: unset;
          animation: 0.5s infinite shake;
        }
        > video {
          // height: 100%;
          height: 450px;
        }
      }
    }
    .pet-reveal {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      // background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.5s;
      > img {
        height: 100%;
        z-index: 1;
        &.bg {
          height: 100%;
          position: absolute;
          z-index: 0;
        }
      }
    }
    &.video-ended {
      // background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 35%, rgba(#0000, 0.55) 100%);
      > .video {
        // box-shadow: none !important;
        // animation-direction: reverse;
        // animation-fill-mode: backwards;
      }
    }
  }

  .nft-grid {
    margin-top: 3rem;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    color: $white;

    .no-nfts {
      grid-column: 2 / span 2;
      justify-self: center;
      align-self: center;
    }

    .nft {
      background: linear-gradient(180deg, #350756 0%, rgba(64, 19, 85, 0) 100%);
      border-radius: 10px;
      padding: 0.5rem;
      text-align: center;

      .image {
        margin-bottom: 1.5rem;
        height: 256px;
        img {
          height: 100%;
          // width: 100%;
          border-radius: 10px;
        }
      }

      .id {
        font-family: $primary-font;
        font-size: 16px;
        // white-space:nowrap;
        color: $white;
        margin: 1.5rem 0;
        &.sm {
          font-size: 14px;
        }
      }

      .button {
        padding: 0.75rem 1rem;
      }

      .role {
        font-size: 16px;
        font-weight: 300;
        color: $white;
        margin: 1.5rem 0;
      }
    }
  }
}
@media screen and (min-width: $screen-sm) {
  .hatching {
    > .container {
      .nft-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
}
@media screen and (min-width: $screen-lg) {
  .hatching {
    > .container {
      .nft-grid {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
</style>
