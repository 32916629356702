<template>
  <header>
    <nav>
      <div class="mobile-nav">
        <img src="@/assets/logo-animated.gif" />
        <button href="#" class="burger-menu" @click="mobileMenu = true">
          <span></span>
        </button>
      </div>

      <div
        v-bind:class="{ links: true, mobile: mobileMenu }"
        @click.self="mobileMenu = false"
      >
        <div class="links-container">
          <div class="pv-logo" @click="mobileMenu = false">
            <img src="@/assets/thepixlverse-plain.png" alt="" />
          </div>
          <div class="left">
            <a @click="scroll('pixel-pets')"><span>Pixl Pets</span></a>
            <a @click="scroll('roadmap')"><span>Roadmap</span></a>
            <a @click="scroll('partners')"><span>Partners</span></a>
            <a @click="scroll('team')"><span>Team</span></a>
            <a href="/litepaper"><span>Litepaper</span></a>
            <a href="/hatch"><span>Hatch</span></a>
            <a href="https://gitbook.thepixlverse.com" target="_"><span>Gitbook</span></a>
          </div>
          <div class="right">
            <!--<a @click="openMintModal()" v-if="shouldMintButtonShow === true"><span>Mint Now</span></a>-->
            <!-- TODO add once genesis collection launches -->
            <a href="https://opensea.io/collection/pixl-pets-genesis" target="_blank">
              <img src="@/assets/opensea.png" />
              <span>Opensea </span><span>Pets</span>
            </a>
            <a href="https://opensea.io/collection/pixlverse-items" target="_blank">
              <img src="@/assets/opensea.png" />
              <span>Opensea </span><span>Items</span>
            </a>
            <a href="https://twitter.com/ThePixlverse" target="_blank">
              <img src="@/assets/twitter.png" />
              <span>Twitter</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  components: {},
  methods: {
    scroll(id) {
      if (this.$route.name == 'Home') {
        this.mobileMenu = false;
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
        });
      } else {
        this.$router.push({ name: 'Home' })
      }
    },
    openMintModal() {
      this.mobileMenu = false;
      this.$store.state.mintModal = true;
    },
  },
  computed: {
    // TODO refactor into shared function
    shouldMintButtonShow: function () {
      const vm = this;
      // hide if not over the presale open
      if (vm.$store.state.currentTime <= vm.$store.state.presaleTime) {
        return false;
      }
      // hide mint button if supply is over 15k
      if (vm.$store.state.totalSupply >= vm.maxSupply) {
        return false;
      }
      // hide between presale close and public open
      if (
        vm.$store.state.currentTime >= vm.$store.state.presaleCloseTime &&
        vm.$store.state.currentTime < vm.$store.state.publicSaleTime
      ) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      mobileMenu: false,
    };
  },
};
</script>

<style lang="scss">
header {
  background: linear-gradient(
    180deg,
    rgba(#311857, 0.5) 0%,
    rgba(70, 57, 87, 0) 100%
  );
  nav {
    display: flex;
    justify-content: flex-end;
    .mobile-nav {
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      img {
        opacity: 0.8;
        filter: drop-shadow(0 0 10px rgba(#4ea237, 0.5));
        transform: scale(1.3);
        color: black;
      }
      .burger-menu {
        padding: 1rem;
        margin: 1rem;
        background: rgba(black, 0.5);
        border-radius: 200px;
        border: none;
        box-shadow: 0 0 10px #504b7b;
        > span {
          width: 24px;
          height: 10px;
          position: relative;
          display: inline-block;
          border-top: 3px solid white;
        }
        span:after,
        span:before {
          content: "";
          width: 100%;
          height: 3px;
          left: 0;
          background-color: white;
          position: absolute;
        }
        span:after {
          bottom: -2px;
        }
        span:before {
          top: 3px;
        }
      }
    }

    .links {
      display: none;
      &.mobile {
        display: block;
        position: fixed;
        background: rgba($black, 0.8);
        backdrop-filter: blur(10px);
        height: 100vh;
        width: 100%;
        z-index: 99999;
        top: 0;
        .links-container {
          display: grid;
          background: transparent;
          // box-shadow: inset 0 0 100px rgba(white, 0.3);
          // margin: 5rem 3rem;
          border-radius: 10px;
          padding: 2rem 0;
          .pv-logo {
            justify-content: center;
            padding-bottom: 2rem;
            img {
              width: 100%;
              max-width: 250px;
            }
          }
          > * {
            display: grid;
            a {
              // backdrop-filter: blur(1px);
              box-shadow: inset 0 0 50px rgba(white, 0.05);
              border-bottom: solid rgba(#fff, 0.1) 1px;
              padding: 1rem 2rem;
            }
          }
          > .right {
            img {
              display: none;
            }
          }
          > .left {
            a:first-child {
              border-top: solid rgba(#fff, 0.1) 1px;
            }
          }
        }
      }
    }

    a {
      border: 0;
      text-shadow: $text-shadow-3d-sm;
      font-size: 20px;
      position: relative;
      margin-right: 20px;
      &:hover span {
        border-bottom: solid rgba(#fff, 0.5) 2px;
      }
    }

    .mobile a {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  header {
    nav {
      justify-content: flex-start;
      .mobile-nav {
        display: none;
      }
      .links {
        display: block;
        width: 100%;
        padding: 1.75rem 2rem;
        .links-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: center;
          .pv-logo {
            display: none;
          }
          .right {
            justify-self: flex-end;
            display: flex;
            align-items: center;
            > a {
              position: relative;
              display: flex;
              align-items: center;
              img + span {
                display: none;
                // position: absolute;
                // left: 0;
                // font-size: 11px;
                // width: 100%;
              }
              img + span + span {
                margin-left: 10px;
              }
              img {
                position: relative;
                width: 32px;
                margin-left: 2rem;
                filter: drop-shadow(0 0 5px rgba(black, 0.5));
                transition: transform 0.1s;
                &:hover {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
