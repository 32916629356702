var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roadmap",attrs:{"id":"roadmap"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"rm-area"},[_c('div',{staticClass:"planet1",style:({ opacity: _vm.area25 })},[_c('div',{style:({
            transform: ("translateY(calc(500px - " + (500 * _vm.area25) + "px))"),
          })},[_c('img',{attrs:{"src":require("@/assets/l1.gif")}})])]),_c('div',{staticClass:"planet2",style:({ opacity: _vm.area50 })},[_c('div',{style:({
            transform: ("translateY(calc(500px - " + (500 * _vm.area50) + "px))"),
          })},[_c('img',{attrs:{"src":require("@/assets/l2.gif")}})])]),_c('div',{staticClass:"planet3",style:({ opacity: _vm.area75 })},[_c('div',{style:({
            transform: ("translateY(calc(500px - " + (500 * _vm.area75) + "px))"),
          })},[_c('img',{attrs:{"src":require("@/assets/l3.gif")}})])]),_c('div',{staticClass:"planet4",style:({ opacity: _vm.area100 })},[_c('div',{style:({
            transform: ("translateY(calc(500px - " + (500 * _vm.area100) + "px))"),
          })},[_c('img',{attrs:{"src":require("@/assets/l4.gif")}})])]),_c('div',{staticClass:"area25",style:({ transform: ("translateY(calc(500px - " + (500 * _vm.area25) + "px))") })},[_c('span',{staticClass:"goal donation"},[_vm._v("Q4 2021 + Q1 2022")]),_c('h1',[_vm._v("Phase 1")]),_vm._m(1)]),_c('div',{staticClass:"area50",style:({ transform: ("translateY(calc(500px - " + (500 * _vm.area50) + "px))") })},[_c('span',{staticClass:"goal merch"},[_vm._v("Q1/Q2 2022")]),_c('h1',[_vm._v("Phase 2")]),_vm._m(2)]),_c('div',{staticClass:"area75",style:({ transform: ("translateY(calc(500px - " + (500 * _vm.area75) + "px))") })},[_c('span',{staticClass:"goal donation"},[_vm._v("Q2/Q3 2022")]),_c('h1',[_vm._v("Phase 3")]),_vm._m(3)]),_c('div',{staticClass:"area100",style:({ transform: ("translateY(calc(500px - " + (500 * _vm.area100) + "px))") })},[_c('span',{staticClass:"goal drop"},[_vm._v("Q4 2022+")]),_c('h1',[_vm._v("Phase 4")]),_vm._m(4)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rm-head"},[_c('h1',[_vm._v("Roadmap")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"description"},[_c('p',[_c('ul',[_c('li',[_c('b',[_vm._v("November 6th 2021")]),_vm._v(" - $PIXL Utility Token Launch")]),_c('li',[_c('b',[_vm._v("Decemeber 1st, 2021")]),_vm._v(" - $PIXL Marketplace Launched")]),_c('li',[_c('b',[_vm._v("December 21st, 2021")]),_vm._v(" - AlphaV2 Pixlverse Homes module launched! Scalable multi-player network architecture achieved")]),_c('li',[_c('b',[_vm._v("February 4th-5th, 2022")]),_vm._v(" - Genesis Pixl Pets Mint")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"description"},[_c('p',[_c('ul',[_c('li',[_vm._v("Gamified Yield Farming - 1st iteration of Pixlverse \"Farms\" module ")]),_c('li',[_vm._v("Layer 2 Scaling Solution")]),_c('li',[_vm._v("Pixl Pet Breeding")]),_c('li',[_vm._v("Pixlverse Land + Dev Grant program ")]),_c('li',[_vm._v("Begin Development of Founding Community HQs")]),_c('li',[_vm._v("Pixlverse Open World \"First Walkthroughs\"")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"description"},[_c('p',[_c('ul',[_c('li',[_vm._v("Pixl Pets Arena, Battling + Raids first Modules")]),_c('li',[_vm._v("Pixlverse Guilds + Palaces")]),_c('li',[_vm._v("Further development of the Open world")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"description"},[_c('p',[_c('ul',[_c('li',[_vm._v("SDK release")]),_c('li',[_vm._v("Pixlverse Governance Token")]),_c('li',[_vm._v("PixlverseDAO")]),_c('li',[_vm._v("Pixlverse Founding Communities receive Governance tokens")]),_c('li',[_vm._v("To Development and beyond…….")])])])])}]

export { render, staticRenderFns }