<template>
  <div class="pixel-pets" id="pixel-pets">
    <div class="container" v-scroll-reveal="{ delay: 450 }">
      <div class="pp-head">
        <h1>Pixl Pets</h1>
        <h2>Your companions throughout the Pixlverse. <br>Minting soon!</h2>
      </div>
    </div>
    <div class="container pet-info" v-scroll-reveal="{ delay: 450 }">
      <div class="eggs">
        <div class="egg vertical" v-for="egg in eggs" :key="egg._id">
          <div class="image">
            <img :src="egg.img" />
          </div>
          <div class="info centered">
            <h2>{{ egg.name }}</h2>
            <p v-if="egg.description">{{ egg.description }}</p>
          </div>
        </div>
        <div class="cta">
          <!-- <Button text="Mint Now" /> -->
        </div>
      </div>
      <Tilt
        :options="{
          startX: deviceWidth() >= 760 ? 40 : 0,
          startY: deviceWidth() >= 760 ? 0 : 40,
          reset: true,
        }"
      >
        <div class="pets">
          <div
            v-for="pet in pets"
            :key="pet._id"
            v-bind:class="{
              pet,
              [`pet${pet._id}`]: true,
              small: pet.size === 'small',
            }"
          >
            <img :src="pet.img" />
          </div>
        </div>
      </Tilt>
    </div>
    <div class="plane"></div>
  </div>
</template>

<script>
import Button from "./ui/Button.vue";
import Tilt from "vanilla-tilt-vue";
import Partners from "../data/Partners.json";
export default {
  name: "Partners",
  components: {
    Button,
    Tilt,
  },
  mounted() {},
  methods: {
    getPartnerImage(partner) {
      return require("@/assets/partners/" + partner.img);
    },
    deviceWidth() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width;
    },
  },
  data() {
    return {
      collection: Partners,
      pets: [
        {
          _id: 0,
          img: require("@/assets/pets/AIRWEBSITE.png"),
        },
        {
          _id: 1,
          img: require("@/assets/pets/EARTHWEBSITE.png"),
        },
        {
          _id: 2,
          img: require("@/assets/pets/FIREWEBSITE.png"),
          size: "small",
        },
        {
          _id: 3,
          img: require("@/assets/pets/WATERWEBSITE.png"),
        },
      ],
      // TODO add descriptions!
      eggs: [
        {
          _id: 0,
          img: require("@/assets/eggs/egg.png"),
          name: "Air",
          description: "",
        },
        {
          _id: 1,
          img: require("@/assets/eggs/egg.png"),
          name: "Earth",
          description: "",
        },
        {
          _id: 2,
          img: require("@/assets/eggs/egg.png"),
          name: "Fire",
          description: "",
        },
        {
          _id: 3,
          img: require("@/assets/eggs/egg.png"),
          name: "Water",
          description: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.bf {

  .pixel-pets {
    pointer-events: all;
    // margin-bottom: 10rem;
    position: relative;
    background: linear-gradient(
      0deg,
      rgba(70, 12, 61, 0) 0%,
      rgba(143, 0, 255, 0.125) 44%,
      rgba(23, 3, 32, 0) 100%
    );
    padding-bottom: 200px;
    .pp-head {
      padding-bottom: 2rem;
      h2 {
        line-height: 150%;
      }
    }
    .pet-info {
      display: grid;
      grid-template-columns: repeat(-1, 1fr);
      .eggs {
        gap: 2rem;
        display: grid;
        grid-column: 1;

        // grid-template-rows: repeat(1, 1fr);
        // grid-auto-rows: minmax(min-content, max-content);
        .cta {
          .button {
            width: 200px;
            margin: 0 auto;
          }
        }
        .egg {
          display: flex;
          background: linear-gradient(
            270deg,
            rgba(175, 19, 178, 0.1) 0%,
            rgba(96, 25, 244, 0.7) 100%
          );
          box-shadow: -25px 0px 50px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 20px 15px;
          .image {
            display: flex;
            align-items: center;
            margin-right: 1rem;
          }
          .info.centered {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            h2 {
              margin: 0;
            }
          }
          h2 {
            font-family: $primary-font;
            text-shadow: $text-shadow-3d;
            color: $white;
            margin-left: 3px;
            margin-top: 10px;
          }
          p {
            color: $white;
            opacity: 0.8;
            margin: 10px 0;
            font-weight: normal;
            font-size: 14px;
          }
        }
      }
      .pets {
        margin-top: 6rem;
        margin-bottom: -2rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        gap: 2rem;
        .pet {
          &.pet0,
          &.pet2 {
            justify-self: flex-end;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba($black, 0.8);
          width: 100px;
          height: 100px;
          border-radius: 5px;
          padding: 20px;
          box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05), -3px -3px 0px #ffffff,
            3px 3px 0px #30c2fa;
          backdrop-filter: blur(4px);
          img {
            object-fit: scale-down;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .plane {
      position: absolute;
      background: url("~@/assets/3d-plane.svg") no-repeat;
      background-position: bottom;
      background-size: 500%;
      bottom: -2px;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .bf {
    .pixel-pets {
      .plane {
        background-size: 250%;
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .bf {
    .pixel-pets {
      // margin-bottom: 10rem;
      position: relative;
      background: linear-gradient(
        0deg,
        rgba(70, 12, 61, 0) 0%,
        rgba(143, 0, 255, 0.125) 44%,
        rgba(23, 3, 32, 0) 100%
      );
      // box-shadow: 0 0 1000px #280240;
      padding-bottom: 200px;
      .bf-head {
        padding-bottom: 5rem;
      }
      .pet-info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        .eggs {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: minmax(min-content, max-content);
          // grid-auto-rows: minmax(250px, 250px);
          gap: 2rem;
          .cta {
            grid-column: span 2;
            .button {
              width: 200px;
              margin: 0 auto;
            }
          }
          .egg {
            display: flex;
            background: linear-gradient(
              270deg,
              rgba(175, 19, 178, 0.1) 0%,
              rgba(96, 25, 244, 0.7) 100%
            );
            box-shadow: -25px 0px 50px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 20px 15px;
            .image {
              display: flex;
              align-items: center;
              margin-right: 1rem;
            }
            &.vertical {
              flex-direction: column;
              align-items: center;
              .image {
                margin-bottom: 1.5rem;
                margin-right: 0;
              }
            }
            h2 {
              font-family: $primary-font;
              text-shadow: $text-shadow-3d;
              color: $white;
              margin-left: 3px;
              margin-top: 10px;
            }
            p {
              color: $white;
              opacity: 0.8;
              margin: 10px 0;
              font-weight: normal;
              font-size: 14px;
            }
          }
        }
        .pets {
          margin-top: -4rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: flex-start;
          gap: 2rem;
          .pet {
            &.pet1,
            &.pet3 {
              margin-top: -80px;
            }
            &.pet0,
            &.pet2 {
              justify-self: flex-end;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba($black, 0.8);
            width: 230px;
            height: 230px;
            border-radius: 5px;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05), -3px -3px 0px #ffffff,
              3px 3px 0px #30c2fa;
            backdrop-filter: blur(4px);
            img {
              object-fit: scale-down;
              width: 100%;
              height: 100%;
            }
            &.small {
              padding: 40px;
              width: 100px;
              height: 100px;
            }
          }
        }
      }
      .plane {
        position: absolute;
        background: url("~@/assets/3d-plane.svg") no-repeat;
        background-position: bottom;
        background-size: 100%;
        bottom: -2px;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }
  }
}
@media screen and (min-width: $screen-lg) {
}
@media screen and (min-width: $screen-xlg) {
}
@media screen and (min-width: $screen-xxlg) {
}
</style>
