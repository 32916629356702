
<template>
  <div>
    <Header />
    <div class="litepaper">
        <!-- Render a big ass image -->
        <img src="@/assets/litepaper.png" alt="" />

        <!-- Or just render the PDF from google... image looka betta tho -->
        <!-- <iframe src="https://drive.google.com/file/d/1U67wPc-KTmYC4ZX06foFDcDRMC602K_G/preview"  allow="autoplay" ></iframe> -->
    </div>
  </div>
</template>

<script>

import Header from "../components/Header";

export default {
  name: "Litepaper",
  components: {
    Header,
  },
  methods: {
      updateScroll() {
        this.scrollPosition = window.scrollY;
        this.numberDepth = this.numberDepth - 0.02;
        this.bgCol = `filter:hue-rotate(${this.scrollPosition / 20}deg)`;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  data() {
    return {
      scrollPosition: 0,
      bgCol: 0,
      numberDepth: 1,
    };
  },
};
</script>
<style lang="scss">

.litepaper {
    text-align: center;
}

.litepaper iframe {
    height: 85vh;
}

.litepaper img {
    width: 100%;
}


@media screen and (max-width: $screen-lg) {
    .litepaper iframe {
        width: 90%;
    }
}

@media screen and (min-width: $screen-lg) {
    .litepaper iframe {
        width: 66%;
    }
}

</style>
