<template>
  <footer>
    <nav class="container">
      <div class="grid-item">
        <img class="brand" src="@/assets/pixl.png" alt="" />
      </div>
      <div class="grid-item">
        <div class="socials">
          <a href="https://discord.gg/pixllabs" target="_blank"
            ><img src="@/assets/discord.png"
          /></a>
          <a href="https://twitter.com/ThePixlverse" target="_blank"
            ><img src="@/assets/twitter.png"
          /></a>
          <!-- TODO update for PP genesis collection -->
          <a href="https://opensea.io/collection/pixlverse-items" target="_blank"
            ><img src="@/assets/opensea.png"
          /></a>
          <p>Copyright &copy; Pixlverse 2022 - All Rights Reserved.</p>
        </div>
      </div>
    </nav>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@keyframes rock {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0);
  }
}

footer {
  z-index: 5;
  padding: 0.5rem 0;
  backdrop-filter: blur(50px);
  margin: 0 auto;
  left: 0;
  right: 0;
  nav {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    .brand {
      max-width: 5rem;
      text-align: left;
      animation: rock 2s ease-in-out infinite;
    }
    .socials {
      text-align: center;
      margin-top: 1rem;
      p {
        color: rgba(255, 255, 255, 0.2);
        font-size: 0.8rem;
      }
      a {
        margin: 0 1rem;
        border: 0;
        img {
          max-width: 2rem;
        }
      }
    }
  }
}
</style>
