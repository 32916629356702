<template>
  <div>
    <Header />
    <div class="home">

      <HeroSection />

      <!-- Mission -->
      <!-- Pets -->
      <!-- Roadmap -->
      <!-- Partners -->
      <!-- Team -->

      <!-- TODO move stuff out of Parnters thingy -->
      <Partners />
      <Team />


      <!-- Team -->
    </div>
  </div>
</template>

<script>
import HeroSection from "../components/HeroMintSection";
import Partners from "../components/Partners";
import Header from "../components/Header";
import Team from "../components/Team";

export default {
  name: "Home",
  components: {
    HeroSection,
    Partners,
    Header,
    Team,
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      this.numberDepth = this.numberDepth - 0.02;
      this.bgCol = `filter:hue-rotate(${this.scrollPosition / 20}deg)`;
    },
  },
  mounted() {
    let vm = this;
    window.addEventListener("scroll", this.updateScroll);
    vm.$store.dispatch("FetchMintedSupply");
    setInterval(() => vm.$store.dispatch("FetchCountDownText"), 1000);
  },
  data() {
    return {
      scrollPosition: 0,
      bgCol: 0,
      numberDepth: 1,
    };
  },
};
</script>
<style lang="scss">
@media screen and (min-width: $screen-sm) {
}
@media screen and (min-width: $screen-md) {
  // .home {
  //   margin-top: 5rem;
  // }
}
@media screen and (min-width: $screen-lg) {
  .home {
    margin-top: 14rem;
  }
}
@media screen and (min-width: $screen-xlg) {
  .home {
    margin-top: 16rem;
  }
}
@media screen and (min-width: $screen-xxlg) {
  .home {
    margin-top: 26.5rem;
  }
}
</style>
