var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"blur"}),_c('Header'),_c('div',{staticClass:"hatching"},[(_vm.hideHatchUI)?_c('div',{staticClass:"container"},[_vm._m(0)]):_c('div',{staticClass:"container"},[_vm._m(1),(_vm.$store.state.loadingErrorMsg !== '')?_c('h3',[_vm._v(" "+_vm._s(_vm.$store.state.loadingErrorMsg)+" ")]):_vm._e(),(_vm.$store.state.provider == null)?_c('div',{staticClass:"connect-wallet"},[_c('Button',{attrs:{"text":"Connect Wallet"},on:{"btnClicked":_vm.connectWallet}})],1):(!_vm.$store.state.hatching.nftsLoaded)?_c('h3',[_vm._v(" Sign the message to login. ")]):_vm._e(),_c('div',{staticClass:"nft-grid"},[_vm._l((_vm.$store.state.hatching.nfts),function(nft,idx){return _c('div',{key:idx,staticClass:"nft"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":nft.image_preview_url.replace(
                  'ipfs://',
                  'https://cloudflare-ipfs.com/ipfs/'
                )}})]),_c('div',{staticClass:"id"},[_vm._v("#"+_vm._s(nft.token_id))]),(nft.isHatched)?_c('Button',{attrs:{"text":'View on Opensea'},on:{"btnClicked":function($event){return _vm.viewOnOpensea(nft)}}}):_c('Button',{attrs:{"text":'Hatch'},on:{"btnClicked":function($event){return _vm.hatch(nft)}}})],1)}),(
            !_vm.$store.state.hatching.nfts.length &&
            _vm.$store.state.hatching.nftsLoaded
          )?_c('div',{staticClass:"no-nfts"},[_c('h3',[_vm._v("You do not own any Pixl Pets")])]):_vm._e()],2),(
          _vm.$store.state.hatching.nftsLoaded &&
          _vm.$store.state.hatching.nfts.length
        )?_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})]):_vm._e(),(_vm.revealHatch)?_c('div',{staticClass:"hatched",class:{ 'video-ended': _vm.hatchingEnded }},[_c('h1',{style:({ opacity: _vm.hatchingEnded ? 1 : 0 })},[_vm._v(" "+_vm._s(_vm.petRevealName)+" ")]),_c('div',{staticClass:"video"},[_c('div',{staticClass:"video-container",class:( _obj = {
              shaking: !_vm.hatchingEnded,
              revealed: _vm.hatchingEnded
            }, _obj[_vm.selectedPetElement] = true, _obj )},[_c('video',{class:{ ended: _vm.hatchingEnded },attrs:{"autoplay":"","muted":""},domProps:{"muted":true},on:{"ended":_vm.hatchingVideoEnded}},[_c('source',{attrs:{"src":_vm.eggVideoFromRecentHatch,"type":"video/webm"}})]),_c('div',{staticClass:"pet-reveal",style:({ opacity: _vm.hatchingEnded ? 1 : 0 })},[_c('img',{attrs:{"src":_vm.petRevealImage}})])])]),_c('Button',{staticClass:"close",style:({ opacity: _vm.hatchingEnded ? 1 : 0 }),attrs:{"text":"Close"},on:{"btnClicked":_vm.closeHatching}})],1):_vm._e()],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t-head"},[_c('h1',[_vm._v("Hatching will be back soon!")]),_c('h3',[_vm._v(" Please excuse this quick interruption, we are currently improving our Metadata! ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t-head"},[_c('h1',[_vm._v("Hatch your Pixl Pets")])])}]

export { render, staticRenderFns }