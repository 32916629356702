<template>
  <div class="modal">
    <div class="ol" @click.self="overlayExit"></div>
    <div v-if="!$store.state.tx.started" class="md">
      <div class="md-head">
        <h1>PIXL Pets Mint</h1>
        <h2
          v-if="
            $store.state.mintProgress.loaded === false &&
            $store.state.account !== null
          "
        >
          Loading your wallet... Please wait!
        </h2>
        <h2
          v-if="
            $store.state.mintProgress.loaded === true &&
            !$store.state.mintProgress.publicSaleMints &&
            !$store.state.mintProgress.presaleMints &&
            !$store.state.mintProgress.freeMints
          "
        >
          This address has used all its available minting allocations.
          <br /><br />
          Thank you for minting!
        </h2>
      </div>
      <div class="md-body">
        <Button
          @btnClicked="attemptConnect"
          :text="connectButtonText"
          v-if="$store.state.account === null"
        />

        <!-- Claim 1 FREE & 2 Presale Pets -->
        <div
          v-if="
            $store.state.mintProgress.freeMints > 0 &&
            $store.state.mintProgress.presaleMints >= 2
          "
          style="padding-bottom: 1rem"
        >
          <h2 style="margin-top: 0">3 out of 3 to mint: 0.176 ETH</h2>
          <Button
            :text="`Claim ${$store.state.mintProgress.freeMints} FREE and ${$store.state.mintProgress.presaleMints} Presale Pixl Pets (Save Gas!)`"
            @btnClicked="omegaMint"
            :class="validInput ? 'activeBtn' : ''"
          />
          <div class="seperator"></div>
        </div>

        <!-- Claim Free Mint -->
        <div v-if="$store.state.mintProgress.freeMints > 0">
          <Button
            :text="`Claim ${$store.state.mintProgress.freeMints} FREE Pixl Pet`"
            @btnClicked="claimFreeMint"
            :class="validInput ? 'activeBtn' : ''"
          />
          <div class="seperator"></div>
        </div>

        <!-- Presale Mint -->
        <div v-if="$store.state.mintProgress.presaleMints > 0">
          <!--<div class="sappy-slider">
            <input
              v-model="presaleMintAmt"
              type="range"
              min="1"
              :max="$store.state.mintProgress.presaleMints"
              value="1"
              class="sappy-range"
              :class="presaleMintAmt > 5 ? 'shake' : ''"
            />
            <div class="min">1</div>
            <div class="max">
              {{ $store.state.mintProgress.presaleMints }}
            </div>
          </div>-->
          <QuantitySelector
            :min="1"
            :max="$store.state.mintProgress.presaleMints"
            v-model="presaleMintAmt"
          />
          <h2>
            {{ presaleMintAmt }} out of
            {{ $store.state.mintProgress.presaleMints }} to mint:

            <span> {{ (presaleMintAmt * presalePrice).toFixed(3) }} ETH </span>
          </h2>
          <Button
            :text="`Presale Mint Pixl Pet${presaleMintAmt == 1 ? '' : 's'}`"
            @btnClicked="presaleMint"
            :class="validInput ? 'activeBtn' : ''"
            v-if="$store.state.mintProgress.presaleMints > 0"
          />
          <div class="seperator"></div>
        </div>

        <!-- Public Mint -->
        <div v-if="$store.state.mintProgress.publicSaleMints > 0">
          <!--<div class="sappy-slider">
            <input
              v-model="mintAmt"
              type="range"
              min="1"
              :max="$store.state.mintProgress.publicSaleMints"
              value="1"
              class="sappy-range"
            />
            <div class="min">1</div>
            <div class="max">
              {{ $store.state.mintProgress.publicSaleMints }}
            </div>
          </div>-->
          <div v-if="$store.state.currentTime >= $store.state.publicSaleTime">
            <QuantitySelector
              :min="1"
              :max="$store.state.mintProgress.publicSaleMints"
              v-model="mintAmt"
            />
            <h2>
              {{ mintAmt }} out of
              {{ $store.state.mintProgress.publicSaleMints }} to mint:
              <span> {{ (mintAmt * publicPrice).toFixed(2) }} ETH </span>
            </h2>
            <Button
              :text="`Public Mint Pixl Pet${mintAmt == 1 ? '' : 's'}`"
              @btnClicked="mint"
              :class="validInput ? 'activeBtn' : ''"
            />
          </div>
          <div v-else>
            <h2>Public sale opens in {{ publicSaleTimeFormatted }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div v-if="$store.state.tx.started" class="tx-wait">
      <!-- transaction header -->
      <img
        v-if="!$store.state.tx.completed && !$store.state.tx.failed"
        src="@/assets/pixl.png"
      />
      <h1 class="tx-failed" v-if="$store.state.tx.failed">
        Transaction failed!
      </h1>
      <h1 class="tx-complete" v-else-if="$store.state.tx.completed">
        Transaction complete!
      </h1>
      <h1 v-else>Transaction pending...</h1>
      <!-- transaction body -->
      <div class="tx-hash" v-if="!$store.state.tx.failed">
        <div class="tx-minting">
          <span v-if="$store.state.tx.hash === false">
            Waiting to Mint Pixl Pets
          </span>
          <span v-else>
            {{ totalBeingMinted }}x Pixl Pet has been mint minted -
            <a
              :href="'https://etherscan.io/tx/' + $store.state.tx.hash"
              target="_blank"
            >
              View on etherscan
            </a>
            <span v-if="$store.state.tx.completed === true">&#10003;</span>
          </span>
        </div>
        <div
          class="tx-confirm"
          v-if="$store.state.tx.hash && !$store.state.tx.completed"
        >
          Confirming minting transaction... Please wait.
        </div>
      </div>
      <div class="tx-hash" v-else>
        <div v-if="$store.state.tx.failedMsg">
          {{ $store.state.tx.failedMsg }}
        </div>
        <div v-else>Something went wrong. Please try again.</div>
      </div>
      <div
        class="close"
        @click="closePrompt"
        v-if="$store.state.tx.completed || $store.state.tx.failed"
      >
        Close
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from "web3";
import Button from "../ui/Button.vue";
import QuantitySelector from "../ui/QuantitySelector.vue";
import PixelPetsAbi from "../../data/abi/ERC721.json";
import { toHHMMSS } from "../../helpers/timer";
export default {
  name: "MintModal",
  components: {
    Button,
    QuantitySelector,
  },
  computed: {
    publicSaleTimeFormatted() {
      const vm = this;
      return toHHMMSS(
        vm.$store.state.publicSaleTime - vm.$store.state.currentTime
      );
    },
    validInput() {
      return (
        this.mintAmt >= 1 &&
        this.mintAmt <= 8 &&
        this.$store.state.account !== null
      );
    },
    connectButtonText() {
      let vm = this;
      return vm.$store.state.account !== null
        ? vm.$store.state.account.substring(0, 5) +
            "..." +
            vm.$store.state.account.substring(
              vm.$store.state.account.length,
              vm.$store.state.account.length - 5
            )
        : "Connect";
    },
  },
  methods: {
    closePrompt() {
      let vm = this;
      vm.$store.state.mintModal = !vm.$store.state.mintModal;
      vm.resetTransactionData();
    },
    resetTransactionData() {
      let vm = this;
      vm.$store.state.tx = {
        started: false,
        completed: false,
        failed: false,
        failedMsg: null,
        hash: false,
      };
    },
    overlayExit() {
      let vm = this;
      if (vm.$store.state.tx.completed || !vm.$store.state.tx.started) {
        vm.closePrompt();
      }
    },
    attemptConnect() {
      let vm = this;
      if (vm.$store.state.provider == null) {
        vm.$store.dispatch("ConnectWallet");
      } else {
        console.log("@todo Disconnect Wallet");
        // vm.$store.dispatch("DisconnectWallet");
      }
    },
    async omegaMint() {
      let vm = this;
      console.log("omegaMint");
      const method = vm
        .getContract()
        .methods.omegaMint(
          vm.$store.state.merkleProof.free.proof,
          vm.$store.state.merkleProof.whitelist.proof
        );
      vm.totalBeingMinted = 3;
      await vm.mintUsingMethod(2 * vm.presalePrice, method);
    },
    async claimFreeMint() {
      let vm = this;
      console.log("claimFreeMint");
      const method = vm
        .getContract()
        .methods.claimFreeMint(vm.$store.state.merkleProof.free.proof);
      vm.totalBeingMinted = 1;
      await vm.mintUsingMethod(0, method);
    },
    async presaleMint() {
      let vm = this;
      console.log("presaleMint", vm.presaleMintAmt);
      const method = vm
        .getContract()
        .methods.presaleMint(
          vm.presaleMintAmt,
          vm.$store.state.merkleProof.whitelist.proof
        );
      vm.totalBeingMinted = vm.presaleMintAmt;
      await vm.mintUsingMethod(vm.presaleMintAmt * vm.presalePrice, method);
    },
    async mint() {
      let vm = this;
      console.log("mint", vm.mintAmt);
      const method = vm.getContract().methods.publicMint(vm.mintAmt);
      vm.totalBeingMinted = vm.mintAmt;
      await vm.mintUsingMethod(vm.mintAmt * vm.publicPrice, method);
    },
    async mintUsingMethod(totalEthCost, method) {
      let vm = this;
      const weiValue = Web3.utils.toWei(totalEthCost.toString(), "ether");
      vm.resetTransactionData();
      vm.$store.state.tx.started = true;
      let opts = {
        from: this.$store.state.account,
        value: weiValue,
      };
      try {
        let gasCost = await method.estimateGas(opts);
        console.log("gas_cost", gasCost);
        method
          .send({
            gas: parseInt(1.2 * gasCost),
            from: this.$store.state.account,
            value: weiValue,
          })
          .on("transactionHash", function (hash) {
            console.log("tx_hash", hash);
            vm.$store.state.tx.hash = hash;
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("tx_confirm", [confirmationNumber, receipt]);
          })
          .on("receipt", function (receipt) {
            console.log("tx_succes", receipt);
            vm.$store.state.tx.completed = true;
            vm.$store.dispatch("FetchMintProgress", vm.$store.state.account);
            vm.$store.dispatch("FetchMintedSupply");
          })
          .on("error", function (error) {
            vm.$store.state.tx.failed = true;
            console.error("tx_fail", error);
          });
      } catch (err) {
        vm.$store.state.tx.failed = true;
        try {
          const message = /execution reverted:\s(.*)\s/.exec(err.message)[1];
          vm.$store.state.tx.failedMsg = message;
        } catch (regerr) {
          const possibleError = err.message || "";
          if (possibleError.includes("insufficient funds")) {
            vm.$store.state.tx.failedMsg = "Not enough funds";
          } else {
            vm.$store.state.tx.failedMsg = "Transaction failed, unknown error";
          }
        }
      }
    },
    getContract() {
      let vm = this;
      return new vm.$store.state.web3.eth.Contract(
        PixelPetsAbi,
        process.env.VUE_APP_CONTRACT_ADDRESS
      );
    },
  },
  mounted() {},
  data() {
    return {
      presalePrice: 0.088,
      publicPrice: 0.12,

      presaleMintAmt: 1,
      mintAmt: 1,

      totalBeingMinted: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.modal {
  .ol {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(12px);
    transition: 0.5s ease;
    z-index: 9999 !important;
  }
  .tx-wait {
    // border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    border-radius: 15px;
    backdrop-filter: blur(6px);
    padding: 2rem;
    box-shadow: 0px 0px 50px rgb(255 255 255 / 12%) inset,
      0px 40px 50px #0000006e;
    transition: 0.5s ease;
    transition-delay: 0.5s;
    position: absolute;
    z-index: 9999 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // min-width: 540px;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
    z-index: 1;
    img {
      max-width: 4rem;
      animation: spinner 1s linear infinite;
    }
    .tx-complete {
      color: darkseagreen;
    }
    .tx-failed {
      color: #ff7f7f;
    }
    .tx-confirm {
      color: yellow;
    }
    .tx-hash {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 1.5rem;
      color: white;
      text-decoration: none;
      text-shadow: -0.06em 0 red, 0.06em 0 cyan;
      > div {
        margin-bottom: 1rem;
      }
    }
    .tx-complete {
      color: darkseagreen;
    }
    .close {
      color: white;
      padding: 1rem 2rem;
      background: rgba(255, 255, 255, 0.1);
      // border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
      border-radius: 15px;
      cursor: pointer;
      margin: 1rem;
      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }
  .md {
    max-height: 650px;
    overflow: auto;
    // border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    border-radius: 15px;
    backdrop-filter: blur(6px);
    padding: 2rem;
    box-shadow: 0px 0px 50px rgb(255 255 255 / 12%) inset,
      0px 40px 50px #0000006e;
    transition: 0.5s ease;
    transition-delay: 0.5s;
    position: fixed;
    z-index: 9999 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 1;
    .md-head {
      text-align: center;
      h1,
      h2 {
        font-family: "Press Start 2P";
      }
      h1 {
        font-size: 1.35rem;
        line-height: 1.5rem;
        padding-bottom: 1.5rem;
      }
      h2 {
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.75rem;
        line-height: 2rem;
      }
    }
    .md-body {
      text-align: center;
      h2 {
        color: rgba(255, 255, 255, 1);
        font-size: 1rem;
        line-height: 2rem;
        margin: 1.25rem 0;
      }
      .seperator {
        padding-top: 1.5rem;
        border: 7.5px solid;
        border-image: linear-gradient(
            90deg,
            rgba(white, 0),
            rgba(white, 0.2) 50%,
            rgba(white, 0) 100%
          )
          1;
        border-left: none;
        border-top: none;
        border-right: none;
      }
      .button + .seperator {
        margin-bottom: 0.5rem;
      }
      .sappy-slider {
        width: 100%;
        margin: 3rem 0 1rem 0;
        position: relative;
        input {
          width: 100%;
        }
        .sappy-range {
          -webkit-appearance: none;
          width: 100%;
          height: 5px;
          border-radius: 5px;
          background: #d3d3d375;
          outline: none;
          opacity: 1;
          -webkit-transition: 0.2s;
          transition: opacity 0.2s;
        }

        .sappy-range::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 3rem;
          height: 3rem;
          border: 0;
          background: url("~@/assets/pixl.png");
          cursor: pointer;
          background-size: cover;
        }

        .sappy-range::-moz-range-thumb {
          width: 3rem;
          height: 3rem;
          border: 0;
          background: url("~@/assets/pixl.png");
          cursor: pointer;
          background-size: cover;
        }
        .min {
          color: #d3d3d375;
          position: absolute;
          left: 0;
          top: 1.25rem;
          font-size: 11px;
          font-weight: 600;
        }
        .max {
          color: #d3d3d375;
          position: absolute;
          right: 0;
          top: 1.25rem;
          font-size: 11px;
          font-weight: 600;
        }
      }
      .button {
        margin: 1rem auto;
        line-height: 20px;
        min-width: 50%;
        &:last-child {
          opacity: 0.5;
        }
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .modal {
    .md,
    .tx-wait {
      max-height: unset;
      overflow: unset;
      width: 100%;
      max-width: 650px;
      margin: 0 auto;
    }
  }
}
</style>
